import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { setError, clearError } from "store/msgStore";
import { ApiGet } from "services/ApiService";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { FiFolderMinus, FiFolderPlus, } from "react-icons/fi";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import AdminParteneriSedii from './AdminParteneri-Sedii';
import AdminParteneriContacte from "./AdminParteneri-Contacte";
import KeyValueDisplay from "../orders/KeyValuesDisplay";


export default function AdminParteneri({ curentUser }) {
    const [data, setData] = useState([]); // need to do a deep copy
    const defaultCount = { pfOk: 0, pfBlock: 0, firmaOk: 0, firmaBlock: 0 };
    const [count, setCount] = useState(defaultCount);
    const [selectOptionsAgenti, setSelectOptionsAgenti] = useState([]);



    let dispatch = useDispatch();

    const calculateAgents = (data) => {
        if (!data || !data.length) {
            return
        }
        
        let agenti = Object.fromEntries(
            Array.from(new Set(data
                .map(item => item.agent))
            )
            .map(value => [value, value])
        )
         
        setSelectOptionsAgenti(agenti)

        console.log('agenti', agenti);
    }

    const prepareData = (data) => {
        let counts = { ...defaultCount };

        data.forEach(p => {
            if (p.persoanaFizica === 'DA') {
                if (p.blocat === 'DA') {
                    counts.pfBlock++;
                } else {
                    counts.pfOk++;
                }
            } else {
                if (p.blocat === 'DA') {
                    counts.firmaBlock++;
                } else {
                    counts.firmaOk++;
                }
            }
        })
        setCount(counts);

        calculateAgents(data)

        setData(data);
    }

    const refresh = () => {
        dispatch(clearError());
        //dispatch(setLoading(true));
        ApiGet('/parteneri', (data) => { prepareData(data) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        //render
    }, [data, selectOptionsAgenti]);

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectOptions = {
        'NU': 'Nu',
        'DA': 'Da',
    };
    
    const getAgentiFilter = () => {
        console.log('getAgentiFilter', selectOptionsAgenti);
        return selectOptionsAgenti
    }

    const columns = [
        {
            dataField: 'idMentor',
            text: 'ID',
            sort: true,
            sortFunc: (a, b, order, dataField) => {
                if (order === 'desc') {
                    return parseInt(b) < parseInt(a);
                }
                return parseInt(b) > parseInt(a); // desc
            },
            editable: false,
            filter: textFilter({ placeholder: 'filtru...' }),
            headerStyle: { width: '70px' }
        },
        {
            dataField: 'cod',
            text: 'Cod',
            sort: true,
            sortFunc: (a, b, order, dataField) => {
                if (order === 'desc') {
                    return parseInt(b) < parseInt(a);
                }
                return parseInt(b) > parseInt(a); // desc
            },
            editable: false,
            filter: textFilter({ placeholder: 'filtru...' }),
            headerStyle: { width: '70px' }
        },
        {
            dataField: 'persoanaFizica',
            text: 'PF',
            sort: true,
            filter: selectFilter({
                placeholder: 'oricare',
                options: selectOptions
            }),
            editable: false,
            headerStyle: { width: '50px' }
        },
        {
            dataField: 'denumire',
            text: 'Denumire',
            sort: true,
            filter: textFilter({ placeholder: 'filtru...' }),
            editable: false,
            headerStyle: { width: '20%' }
        },
        {
            dataField: 'codFiscal',
            text: 'Cod fiscal',
            sort: true,
            filter: textFilter({ placeholder: 'filtru...' }),
            editable: false
        },/*
        {
            dataField: 'registruComert',
            text: 'Reg. Comert',
            sort: true,
            filter: textFilter({ placeholder: 'filtru...' }),
            editable: false
        },
        {
            dataField: 'observatii',
            text: 'Observatii',
            sort: true,
            filter: textFilter({ placeholder: 'filtru...' }),
            editable: false
        },*/
        {
            dataField: 'agent',
            text: 'Agent',
            sort: true,
            filter: selectFilter({
                placeholder: 'oricare',
                options: getAgentiFilter(),
                onFilter: (filterValue) => { 
                    console.log('onFilter', filterValue);
                  }
            }),
            editable: false
        },
        {
            dataField: 'caracCont',
            text: 'Caracterizare contabila',
            sort: true,
            filter: textFilter({ placeholder: 'filtru...' }),
            editable: false,
            headerStyle: { width: '15%' }
        },
        {
            dataField: 'blocat',
            text: 'Blocat',
            sort: true,
            filter: selectFilter({
                placeholder: 'oricare',
                options: selectOptions
            }),
            editable: false,
            headerStyle: { width: '60px' }
        },
        {
            dataField: 'discountFix',
            text: 'Discount',
            sort: true,
            // filter: numberFilter(),
            editable: false,
            headerStyle: { width: '80px' },
            align: 'right'
        },
    ]

    const defaultSorted = [{
        dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
    }];

    /*
        function beforeSaveCell(oldValue, newValue, row, column, done) {
            if (oldValue === newValue) {
                console.log("same value, ignore");
                done(false);
                return;
            }
    
            console.log("save", newValue, row.id)
    
            let payload = { id: row.id, display: newValue }
    
            ApiPut('/catalog/producatori', payload,
                (dataOk) => {
                    console.log("Save OK", dataOk);
                    dispatch(setTempConfirmation("Valoarea [" + newValue + "] a fost salvata cu success."))
                    refresh();
                    done(true)
                },
                (error) => {
                    dispatch(setError(error));
                    done(false)
                });
    
            return { async: true };
        }
    */
    const expandRow = {
        renderer: row => (
            <>
                <h3>{row.denumire}</h3>
                <KeyValueDisplay row={row} columns={4} />
                <hr />
                <AdminParteneriSedii partener={row} />
                <AdminParteneriContacte partener={row} />
            </>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return (<></>);
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (<FiFolderMinus />);
            }
            return (<FiFolderPlus />);
        }
    };

    const customTotal = (from, to, size) => (
        <span style={{ paddingLeft: "3em" }} className="small text-muted react-bootstrap-table-pagination-total">
            rândurile {from} .. {to} / {size}
        </span>
    );

    const paginationOptions = {
        showTotal: true,
        paginationTotalRenderer: customTotal,
        paginationSize: 10,
        pageStartIndex: 1,
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: 'Prima pagină',
        //prePageText: '|<',
        //nextPageText: '>|',
        lastPageText: 'Ultima',
        nextPageTitle: 'Următoarea pagină',
        prePageTitle: 'Pagina precedentă',
        firstPageTitle: 'Prima pagină',
        lastPageTitle: 'Ultima pagină',
    }

    let total = count.firmaBlock + count.firmaOk + count.pfBlock + count.pfOk;

    //https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
    return (
        <>
            <h1>Parteneri</h1>
            <p>Partenerii sunt definiți in Win-Mentor și importați ca atare. Toate modificarile sunt facute in Win-Mentor. Sunt în total {data.length} parteneri: </p>

            <ProgressBar className="mb-3">
                <ProgressBar variant="primary" label={`${count.firmaOk} Persoane Juridice`} now={count.firmaOk * 100 / total} key={1} />
                <ProgressBar striped variant="danger" now={count.firmaBlock * 100 / total} key={2} />
                <ProgressBar variant="success" label={`${count.pfOk} PF`} now={count.pfOk * 100 / total} key={3} />
                <ProgressBar striped variant="danger" label="PF blocate" now={count.pfBlock * 100 / total} key={4} />
            </ProgressBar>


            {(<BootstrapTable
                hover
                keyField='id'
                data={JSON.parse(JSON.stringify(data))}
                columns={columns}
                defaultSorted={defaultSorted}
                /* cellEdit={cellEditFactory({
                     mode: 'dbclick',
                     beforeSaveCell
                 })} */
                pagination={paginationFactory(paginationOptions)}
                filter={filterFactory()}
                filterPosition="top"
                condensed
                expandRow={expandRow}
            // striped
            />
            )}
        </>
    );
}
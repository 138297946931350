import { Row, Col, Container } from "react-bootstrap";

import UserLocations from "./locations/UserLocations";
import PastOrders from "./welcome/LastOrders";
import Welcome from "./welcome/Welcome";
import PastInvoices from "./welcome/LastInvoices";
import WelcomePersonal from "./welcome/WelcomePersonal";

export default function UserProfile({ currentUser }) {


  return (
    (currentUser.meta.tip !== 'A' && currentUser.meta.tip !== 'R') ?
      <Container>
        <Welcome currentUser={currentUser} />
        <Row>
          <Col>
            <PastOrders />
          </Col>
        </Row>

        <Row>
          <Col>
            <PastInvoices />
          </Col>
        </Row>

        <Row xs={1} md={2} className="g-2">
          <Col>
            <UserLocations currentUser={currentUser} />
          </Col>
        </Row>
      </Container>
      :
      <Container>
        <WelcomePersonal currentUser={currentUser} />
      </Container>
  )
}

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setTempConfirmation, setError } from "store/msgStore";
import { ApiGet, ApiPut } from "services/ApiService";
import { Form } from "react-bootstrap";

export default function AdminUserMarca({ userData, refresh }) {

    const [personal, setPersonal] = useState([]);
    const [noRights, setNoRights] = useState(false);

    const dispatch = useDispatch();

    console.log('userData', userData);
    useEffect(() => {
        ApiGet('/personal', (data) => { setPersonal(data) }, (error) => { setNoRights(true) });
    }, []);

    const confirm = (data) => {
        dispatch(setTempConfirmation("Marca a fost salvata"))
        refresh();
    }

    const onChangeRole = (e) => {
        if (!e || !e.target) {
            return
        }
        
        let marca = e.target.value;
        let payload = { userId: userData.id, marca: marca }
            
        ApiPut('/user/' + userData.id, payload, (data) => { confirm(data) }, (error) => { dispatch(setError(error)); });
    }

    if (noRights) {
        return <></>
    }

    return (
        <>
            <Form>
                <Form.Group controlId="marcaSelect">
                    <Form.Label>Marca (din WinMentor)</Form.Label>
                    <Form.Control
                        as="select"
                        value={userData.marca}
                        onChange={onChangeRole}
                    >
                        {!!personal && !!userData && personal.map(p => (
                            <option key={p.marca} value={p.marca}>
                                {p.marca}: {p.nume} {p.prenume} ({p.email})
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Form>
        </>
    )
}
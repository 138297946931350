import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Button, Tab, Tabs, Form } from "react-bootstrap";
import { setError, clearError, setTempConfirmation } from "store/msgStore";
import { ApiGet, ApiPut } from "services/ApiService";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { FiFolderMinus, FiFolderPlus, } from "react-icons/fi";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { NavLink } from "react-router-dom";
import AdminUserAuditAccess from "./AdminUser-AuditAccess";
import AdminUserAdd from "./AdminUser-Add";
import { FaUserEdit } from "react-icons/fa";


export default function AdminUsers({ curentUser }) {
    const [data, setData] = useState([]); // need to do a deep copy
    const defaultCount = { pfOk: 0, pfBlock: 0, firmaOk: 0, firmaBlock: 0 };
    const [count, setCount] = useState(defaultCount);

    let dispatch = useDispatch();

    const prepareData = (data) => {

        let counts = { ...defaultCount };

        data.forEach(p => {
            if (p.persoanaFizica === 'DA') {
                if (p.blocat === 'DA') {
                    counts.pfBlock++;
                } else {
                    counts.pfOk++;
                }
            } else {
                if (p.blocat === 'DA') {
                    counts.firmaBlock++;
                } else {
                    counts.firmaOk++;
                }
            }
        })
        setCount(counts);
        setData(data);
    }

    const refresh = () => {
        dispatch(clearError());

        ApiGet('/users', (data) => { prepareData(data) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        //render
    }, [data]);

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const onChangeActive = (e) => {

        if (!e || !e.target) {
            return
        }
        let checked = e.target.checked
        let rowId = parseInt(e.target.value)
        console.log("Active changed", checked, rowId)

        console.log("Data", data)
        let payload = data.find(r => parseInt(r.id) === rowId)
        
        console.log("Payload", payload)
        payload.status = checked ? 1 : 0

        console.log("Save user active", payload)

        ApiPut('/user/' + rowId, payload,
            (dataOk) => {
                console.log("Save OK", dataOk);
                dispatch(setTempConfirmation("Utilizatorul a fost modificat cu success."))
                refresh()
            },
            (error) => {
                dispatch(setError(error))
            })
    }

    const activeFormatter = (cell, row) => {
        return (
            <Form.Check
                key={`active-${row.id}`}
                type="switch"
                id={`active-${row.id}`}
                checked={row.status === 1}
                value={row.id}
                onChange={onChangeActive}
            />
        )
    }

    const editFormatter = (cell, row) => {
        return (
            <Button as={NavLink} to={`./${row.id}`}
                variant="primary" size="sm" title="Drepturi si discounturi">
                <FaUserEdit />
            </Button>
        )
    }

    const selectOptions = {
        0: 'Inactiv',
        1: 'Activ',
    };


    const columns = [
        {
            dataField: 'status',
            text: 'Activ',
            sort: true,
            editable: false,
            formatter: activeFormatter,
            headerStyle: { width: '5%' },
            filter: selectFilter({
                placeholder: 'tot',
                options: selectOptions
            }),
        },
        {
            dataField: 'marca',
            text: 'Marca',
            sort: true,
            editable: false,
            headerStyle: { width: '70px' },
            filter: textFilter({ placeholder: 'marca...' })
        },
        {
            dataField: 'firstName',
            text: 'Prenume',
            sort: true,
            editable: false,
            filter: textFilter({ placeholder: 'prenume...' })
        },
        {
            dataField: 'lastName',
            text: 'Nume',
            sort: true,
            editable: false,
            filter: textFilter({ placeholder: 'nume...' })
        },
        {
            dataField: 'email',
            text: 'e-Mail',
            sort: true,
            editable: false,
            filter: textFilter({ placeholder: 'email...' })
            //   headerStyle: { width: '20%' }
        },
        {
            dataField: 'denumire',
            text: 'Partener',
            sort: true,
            editable: false,
            filter: textFilter({ placeholder: 'denumire...' })
        },
        {
            dataField: 'codFiscal',
            text: 'Cod Fiscal',
            sort: true,
            editable: false,
            filter: textFilter({ placeholder: 'CIF...' })
        },
        {
            dataField: 'userTypeClase',
            text: 'Acces clase',
            sort: true,
            editable: false,
            headerStyle: { width: '10%' },
            filter: textFilter({ placeholder: 'tip clasa...' })
        },
        {
            dataField: 'userTypeProd',
            text: (<>Acces<br />producatori</>),
            sort: true,
            editable: false,
            headerStyle: { width: '10%', textAlign: 'left' },
            filter: textFilter({ placeholder: 'tip prod...' })
        },
        {
            dataField: 'edit',
            isDummyField: true,
            text: 'Mod.',
            align: 'center',
            headerAlign: 'center',
            sort: false,
            editable: false,
            formatter: editFormatter,
            headerStyle: { width: '5%' }
        },
    ];

    const defaultSorted = [{
        dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
    }];

    const expandRow = {
        renderer: row => (
            <>
                <h3>{row.firstName} {row.lastName}</h3>
                <p className="text-muted">{row.email}</p>
                <AdminUserAuditAccess user={row} />
            </>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return (<></>);
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (<FiFolderMinus />);
            }
            return (<FiFolderPlus />);
        }
    };

    const customTotal = (from, to, size) => (
        <span style={{ paddingLeft: "3em" }} className="small text-muted react-bootstrap-table-pagination-total">
            rândurile {from}-{to} din {size}
        </span>
    );

    const paginationOptions = {
        showTotal: true,
        paginationTotalRenderer: customTotal,
        paginationSize: 10,
        pageStartIndex: 1,
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: 'Prima pagină',
        //prePageText: '|<',
        //nextPageText: '>|',
        lastPageText: 'Ultima',
        nextPageTitle: 'Următoarea pagină',
        prePageTitle: 'Pagina precedentă',
        firstPageTitle: 'Prima pagină',
        lastPageTitle: 'Ultima pagină',
    }

    // let total = count.firmaBlock + count.firmaOk + count.pfBlock + count.pfOk;

    //https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
    return (
        <>
            <h1>Utilizatori</h1>

            <Tabs
                defaultActiveKey="usersList"
                id="usersTabs"
                className="mb-3"
            >
                <Tab eventKey="usersList" title="Parteneri">
                    {(<BootstrapTable
                        hover
                        keyField='id'
                        data={JSON.parse(JSON.stringify(data.filter(r => r.tip === 'P')))}
                        columns={columns.filter(
                            c => c.dataField !== 'marca'
                        )}
                        defaultSorted={defaultSorted}
                        pagination={paginationFactory(paginationOptions)}
                        filter={filterFactory()}
                        filterPosition="top"
                        condensed
                        expandRow={expandRow}
                        bodyStyle={{ width: "100px", maxWidth: "100px", wordBreak: 'break-all' }}
                    // striped
                    />
                    )}
                </Tab>
                <Tab eventKey="usersListRolling" title="Rolling">
                    {(<BootstrapTable
                        hover
                        keyField='id'
                        data={JSON.parse(JSON.stringify(data.filter(r => r.tip !== 'P')))}
                        columns={columns.filter(
                            c => c.dataField !== 'denumire'
                                && c.dataField !== 'codFiscal'
                                && c.dataField !== 'userTypeClase'
                                && c.dataField !== 'userTypeProd')}
                        defaultSorted={defaultSorted}
                        /*cellEdit={cellEditFactory({
                            mode: 'dbclick',
                            beforeSaveCell
                        })}*/
                        pagination={paginationFactory(paginationOptions)}
                        filter={filterFactory()}
                        filterPosition="top"
                        condensed
                        expandRow={expandRow}
                    // striped
                    />
                    )}
                </Tab>
                <Tab eventKey="userAdd" title="+ Adauga">
                    <AdminUserAdd refresh={refresh} />
                </Tab>
            </Tabs>
        </>
    );

}
import { Row, Col, Card, CardBody, CardHeader, CardFooter, Badge } from "react-bootstrap";
import ChangePasswordButton from "../password/ChangePasswordButton";
import Staff from "./Staff";

export default function WelcomePersonal({ currentUser }) {

  console.log(currentUser)

  return (
    <Row xs={1} md={2} className="g-2">
      <Col>
        <Card>
          <CardHeader style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span>Personal Rolling</span> 
            <small>
              {!!currentUser.meta.marca && <Badge pill bg="info">Marca: {currentUser.meta.marca}</Badge>}
              </small>
          </CardHeader>
          <CardBody>
            <h3>Bună ziua, {currentUser.meta.firstName} {currentUser.meta.lastName}</h3>
            <ul>
              <li>Ultimul login reușit: <strong>{currentUser.info.lastSuccesfullLogin}</strong></li>
              <li>Ultimul login eșuat: <strong>{currentUser.info.lastFailedAttempt}</strong></li>
              <li>Erori login: <strong>{currentUser.info.failedAttempts}</strong></li>
            </ul>
          </CardBody>
          <CardFooter style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span><strong>Email:</strong>{" "} {currentUser.username}</span>
            <ChangePasswordButton currentUser={currentUser} />
          </CardFooter>
        </Card>
      </Col>
      
      <Col>
        <Staff />
      </Col>
    </Row>
  )
}

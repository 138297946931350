import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setError, clearError, setLoading } from "store/msgStore";
import { ApiGet } from "services/ApiService";
import { searchResults, setSearchCategory } from "store/productsReducer";
import { useNavigate } from "react-router-dom";



export default function ProductMenu({ curentUser }) {
  const simbolClase = useSelector((store) => store.products.simbolClase);
  const searchCategory = useSelector((store) => store.products.searchCategory);

  const [tree, setTree] = useState([]); // need to do a deep copy
  const [currentPath, setCurrentPath] = useState([]);

  let dispatch = useDispatch()
  let navigate = useNavigate();

  const refresh = () => {
    dispatch(clearError());
    ApiGet('/category', (data) => { setTree(data) }, (error) => { dispatch(setError(error)); });
  }


  const dispatchSearchResults = (data) => {
    dispatch(setLoading(false));
    dispatch(searchResults(data));
  }

  const searchError = (error) => {
    dispatch(setError(error));
    dispatchSearchResults([]);
  }

  useEffect(() => {
    if (!searchCategory) {
      return
    }
    dispatch(setLoading(true));
    dispatch(clearError());
    console.log("ProductMenu searchCategory:", searchCategory, simbolClase[searchCategory]);
    document.title = (!!simbolClase[searchCategory] && !!simbolClase[searchCategory].display) ? simbolClase[searchCategory].display + " - Rolling" : "Rolling";
    navigate('/cauta'); // update bar TODO find some url
    ApiGet(`/search?cat=${searchCategory}`, dispatchSearchResults, searchError);

    setCurrentPath(getPathIds(tree, searchCategory));
    console.log("ProductMenu currentPath:", currentPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCategory]);

  useEffect(() => {
    //render
  }, [tree, currentPath, simbolClase]);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickMenu = (id) => {
    console.log("onClickMenu", id);
    dispatch(setSearchCategory(id));
    navigate("/cauta");
  }

  const getPathIds = (data, categoryId, currentPath = []) => {
    for (const key in data) {
      const node = data[key];

      // If the current node has metadata and matches the categoryId, return the path
      if (node._meta_ && node._meta_.id === categoryId) {
        return [...currentPath, node._meta_.id];
      }

      // If the current node has children, recursively search in them
      if (typeof node === "object" && key !== "_meta_") {
        const path = getPathIds(node, categoryId, [...currentPath, node._meta_?.id || null]);
        if (path) {
          return path.filter((id) => id !== null); // Remove null values from path
        }
      }
    }

    // Return null if the categoryId is not found
    return null;
  };


  // Component for a leaf node (clickable link)
  const MenuLeaf = ({ meta }) => {
    let isActive = !!currentPath ? currentPath.includes(meta.id) : false

    return (
      <span
        className={`dropdown-item text-decoration-none ${isActive ? 'active' : ''}`}
        onClick={() => onClickMenu(meta.id)}
      >
        {simbolClase[meta.id] ? simbolClase[meta.id].display : '?' + meta.id}
      </span>
    )
  }

  // Component for dropdown branches
  const MenuBranch = ({ node, nodeName }) => {
    let children = getAllChildIds(node);
    let isActive = !!currentPath ? currentPath.some((element) => children.includes(element)) : false

    return (
      <li className={`dropdown-submenu ${isActive ? 'active' : ''}`}>
        <span
          className={`dropdown-item ${isActive ? 'active' : ''} `}
          style={{
            fontWeight: "bold",
            whiteSpace: "nowrap",
            cursor: "pointer",
            position: "relative",
          }}
        >
          {nodeName}
        </span>
        <ul
          className="dropdown-menu"
          style={{
            position: "absolute",
            top: "0",
            left: "100%",
            zIndex: 1050,
            minWidth: "100px",
          }}
        >
          {Object.keys(node).map((key) =>
            key === "_meta_" ? (
              <MenuLeaf key={node._meta_.id} meta={node._meta_} />
            ) : (
              <MenuNode key={key} node={node[key]} nodeName={key} />
            )
          )}
        </ul>
      </li>
    )
  };

  // Recursive component for menu nodes
  const MenuNode = ({ node, nodeName }) => {
    if (!node) return null;

    const hasChildren = Object.keys(node).some((key) => key !== "_meta_");

    if (hasChildren) {
      return <MenuBranch node={node} nodeName={nodeName} />;
    } else if (node._meta_) {
      return (
        <li>
          <MenuLeaf meta={node._meta_} />
        </li>
      );
    }

    return null;
  };

  function getAllChildIds(tree, ids = []) {
    // Iterate through each key in the tree
    for (const key in tree) {
        ids.push(tree[key].id);
        if (tree[key]._meta_) {
            // If the _meta_ node exists, push the id to the ids array
            ids.push(tree[key]._meta_.id);
        }
        // If there are nested children, call the function recursively
        for (const childKey in tree[key]) {
            if (childKey !== "_meta_" && typeof tree[key][childKey] === "object") {
                getAllChildIds(tree[key][childKey], ids);
            }
        }
    }
    return ids;
}

  // Main menu with dropdowns
  const MenuDropDown = ({ nodeName, data }) => {
    let children = getAllChildIds(data[nodeName]);
    let isActive = !!currentPath ? currentPath.some((element) => children.includes(element)) : false

    return <li className={`nav-item dropdown ${isActive ? 'active' : ''}`} key={nodeName}>
      <span
        className={`nav-link dropdown-toggle ${isActive ? 'active' : ''}`} 
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={{ cursor: "pointer" }}
      >
        {nodeName}
      </span>
      <ul className="dropdown-menu">
        {Object.keys(data[nodeName]).map((childKey) => childKey === "_meta_" ? (
          <MenuLeaf key={data[nodeName]._meta_.id} meta={data[nodeName]._meta_} />
        ) : (
          <MenuNode key={childKey} node={data[nodeName][childKey]} nodeName={childKey} />
        )
        )}
      </ul>
    </li>;
  }

  // Main Menu Component
  const Menu = ({ data }) => (
    <nav id="product-menu" className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <ul className="navbar-nav flex-wrap">
          {Object.keys(data).map((key) =>
            Object.keys(data[key]).length === 1 && "_meta_" in data[key] ? (
              <li className="nav-item" key={key}>
                <MenuLeaf meta={data[key]._meta_} />
              </li>
            ) : (
              <MenuDropDown nodeName={key} data={data} />
            )
          )}
        </ul>
      </div>
    </nav>
  );


  return (
    <Menu data={tree} />
  );

}
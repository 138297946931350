import { Card, CardBody, CardHeader } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setError, clearError } from "store/msgStore";
import { ApiGet } from "services/ApiService";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';


export default function PastInvoices() {
    const [data, setData] = useState([]);
    const [niceColumns, setNiceColumns] = useState([]);

    const dispatch = useDispatch();

    const columns = [
        {
            dataField: 'codDocument',
            text: 'Cod',
            headerStyle: { width: '100px' }
        },
        {
            dataField: 'serie',
            text: 'Serie',
            headerStyle: { width: '80px' }
        },
        {
            dataField: 'numar',
            text: 'Nr',
            headerStyle: { width: '80px' }
        },
        
        {
            dataField: 'dataFactura',
            text: 'Data',
            headerStyle: { width: '150px' },
            align: 'right'
        },
        {
            dataField: 'valoare',
            text: 'Valoare',
            headerStyle: { width: '10%' },
            align: 'right'
        },
        
        {
            dataField: 'tva',
            text: 'TVA',
            headerStyle: { width: '10%' },
            align: 'right'
        },
            
        {
            dataField: 'sold',
            text: 'Sold',
            sort: true,
            headerStyle: { width: '10%' },
            align: 'right'
        },
        {
            dataField: 'moneda',
            text: 'Moneda',        
            headerStyle: { width: '6%' },
            align: 'center'
        }
    ]

    const prepareColumns = () => {
        if (!data || !data.map) {
            return;
        }
        setNiceColumns(
            data.map((d) => ({
                ...d,
                'valoare': `${parseFloat(d.valoare).toFixed(2)}`,
                'dataFactura': new Intl.DateTimeFormat('ro-RO', { day: '2-digit', month: 'long', year: 'numeric' }).format(new Date(d.dataFactura)),
             })
            ));

    }

    const refresh = () => {
        dispatch(clearError());
        ApiGet('/invoices/client', (data) => { setData(data) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        refresh();
        // eslint-disable-next-line  
    }, [])

    useEffect(() => {
        prepareColumns();
        // eslint-disable-next-line  
    }, [data])

    return (
        <Card>
            <CardHeader>
                Facturi
            </CardHeader>
            <CardBody>
                <BootstrapTable
                    hover
                    //striped
                    keyField='id'
                    data={JSON.parse(JSON.stringify(niceColumns))}
                    columns={columns}
                    condensed
                    pagination={paginationFactory()}
                />
            </CardBody>
        </Card>

    )
}
import { Card, CardBody, CardHeader } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setError, clearError } from "store/msgStore";
import { ApiGet } from "services/ApiService";


export default function Staff() {
    const [data, setData] = useState([]);

    const dispatch = useDispatch();

    const refresh = () => {
        dispatch(clearError());
        ApiGet('/agent', (data) => { setData(data) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        refresh();
        // eslint-disable-next-line  
    }, [])

    console.log(data);

    return (
        <Card>
            <CardHeader>
                Parteneri B2B
            </CardHeader>
            <CardBody>
                {(!data || !data.length)
                    ? <p>Nu sunteți agent pentru niciun client.</p>
                    : <>
                        <p>Sunteți agent pentru următorii clienți:</p>
                        <ul>
                            {data.map((p) => (
                                <li key={p.id}>{p.codFiscal} - {p.denumire}</li>
                            ))}
                        </ul>
                    </>}
            </CardBody>
        </Card>

    )
}